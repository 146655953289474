const GLOBAL_CONSTANT = {
    "API_URL": "https://api.cybersmarties.com.au",
    "CHAT_API": "https://api.chat.cybersmarties.com.au",
    "TIME_ZONE": "Australia/Brisbane",
    "OFFLINE": "true",
    "TEXT_SPEECH_API_URL": "https://aidennvz09.execute-api.eu-west-1.amazonaws.com",
}


export default GLOBAL_CONSTANT;
